import { DataFrame, PanelData } from '@grafana/data';
import { ECharts } from 'echarts';
import 'echarts-gl';
// import 'echarts-gl/src/util/geometry/';
// import { Grid3DComponent } from 'echarts-gl/components';

import { Traj3DPanelOptions } from './types';

export default class Traj3dOptions {
  protected echartInst: ECharts;
  protected panelOptions: Traj3DPanelOptions;
  // protected xAxis: XAXisComponentOption;
  protected trajSeries: DataFrame[] = [];
  constructor(
    echartInst: ECharts,
    data: PanelData,
    panelOptions: Traj3DPanelOptions,
    setPanelOptions: (options: Traj3DPanelOptions) => void
  ) {
    this.echartInst = echartInst;
    this.panelOptions = panelOptions;
    this.initXAxis();

    this.echartInst.setOption({
      title: {
        show: false,
        // text: 'trajectory',
        // textAlign: 'center',
        // left: 'center',
        // top: 'top',
      },
      legend: {
        show: true,
        // left: 'left',
        // orientation: 'vertical',
        // top: 0,
        // data: ['traj1', 'traj2'],
        // textStyle: {
        //   color: '#ccc',
        // },
      },
      tooltip: {
        formatter: (params: any) => {
          // console.log(JSON.stringify(params));
          // console.log('JSON.stringify(params)');
          return `${params.seriesName} DispEW: ${params.data[0]}\nDispNS: ${params.data[1]}\TVD: ${params.data[2]}`;
        },
      },

      grid3D: {
        // environment: '#000',
        light: {
          main: {
            color: 'white',
            intensity: 0.5,
          },
        },
        axisPointer: {
          show: true,
          lineStyle: {
            // color: 'yellow',
            opacity: 0.5,
          },
        },
        viewControl: {
          // autoRotate: true,
          // projection: 'orthographic',
          distance: 200,
          alpha: -180, //rote x
          beta: 180, //rote y
          minAlpha: -18000,
          maxAlpha: 18000,
          minBeta: -18000,
          maxBeta: 18000,
          // center: [0, 0, 100],
        },
      },
      xAxis3D: {
        name: 'DispEW',
      },
      yAxis3D: {
        name: 'DispNS',
      },
      zAxis3D: {
        show: false,
        name: 'TVD',
      },
      series: [
        {
          id: 'traj1',
          type: 'line3D',
          // symbolSize: 50,
          // data: [
          //   [-1, -1, -1],
          //   [0, 0, 0],
          //   [1, 1, 1],
          // ],
          // itemStyle: {
          //   opacity: 1,
          // },
          lineStyle: {
            width: 4,
            opacity: 0.7,
            color: 'blue',
          },
        },
        // {
        //   type: 'scatter3D',
        //   // symbolSize: 50,
        //   // data: [
        //   //   [-1, -1, -1],
        //   //   [0, 0, 0],
        //   //   [1, 1, 1],
        //   // ],
        //   // itemStyle: {
        //   //   opacity: 1,
        //   // },
        //   itemStyle: {
        //     width: 4,
        //     color: 'red',
        //   },
        //   // label: {
        //   //   show: true,
        //   // },
        // },
        {
          id: 'traj2',
          type: 'line3D',
          // symbolSize: 50,
          // data: [
          //   [-1, -1, -1],
          //   [0, 0, 0],
          //   [1, 1, 1],
          // ],
          // itemStyle: {
          //   opacity: 1,
          // },
          lineStyle: {
            width: 4,
            opacity: 0.7,
            color: 'red',
          },
        },
        // {
        //   type: 'scatter3D',
        //   // symbolSize: 50,
        //   // data: [
        //   //   [-1, -1, -1],
        //   //   [0, 0, 0],
        //   //   [1, 1, 1],
        //   // ],
        //   // itemStyle: {
        //   //   opacity: 1,
        //   // },
        //   itemStyle: {
        //     width: 4,
        //     color: 'blue',
        //   },
        //   // label: {
        //   //   show: true,
        //   // },
        // },
      ],
    });
  }

  protected initXAxis() {}

  setData(data: PanelData, update = true) {
    console.log(data);
    this.trajSeries = data.series.filter((s) => s.meta?.custom!['dataObjectType'] === 'Trajectory')!;
    let seriesDt = this.trajSeries.map((tjs) => {
      // let tjs = this.trajSeries[0];
      let fTvd = tjs.fields.find((f) => f.name === 'tvd')!.values.toArray();
      let fDispEW = tjs.fields.find((f) => f.name === 'dispEw')!.values.toArray();
      let fDispNS = tjs.fields.find((f) => f.name === 'dispNs')!.values.toArray();
      let seriesData = fTvd.map((tvd, i) => {
        return [fDispEW[i], fDispNS[i], tvd];
      });

      let sName = tjs.name;

      let s: any = {
        type: 'line3D',
        // id: tjs.name,
        name: sName,
        data: seriesData,
        lineStyle: {
          width: 4,
          opacity: 0.7,
          // color: undefined,
        },
      };

      let etpUrl = tjs.meta?.custom?.etpUrl;
      if (etpUrl !== null && (etpUrl as string).indexOf('Planned') > 0) {
        sName = sName + ' Planned';
        s = {
          ...s,
          name: sName,
          lineStyle: {
            width: 4,
            opacity: 0.7,
            color: 'red',
          },
        };
      }

      return [s];
    });

    // let s1 = this.trajSeries[0];
    // let fTvd = s1.fields.find((f) => f.name === 'tvd')!.values.toArray();
    // let fDispEW = s1.fields.find((f) => f.name === 'dispEw')!.values.toArray();
    // let fDispNS = s1.fields.find((f) => f.name === 'dispNs')!.values.toArray();
    // let seriesData = fTvd.map((tvd, i) => {
    //   return [fDispEW[i], fDispNS[i], tvd];
    // });

    this.echartInst.setOption({
      series: seriesDt.flat(),
    });

    console.log(this.echartInst.getZr());
  }
}

import { PanelPlugin, SetFieldConfigOptionsArgs } from '@grafana/data';
import { Traj3DPanel } from './Traj3DPanel';
import { GraphFieldConfig } from '@grafana/ui';
import { defaultGraphConfig, getGraphFieldConfig } from 'panels/log-plot-panel/copiedFromAlpha75/timeSeriesConfig';
import { LogPlotFieldConfig } from 'panels/log-plot-panel/module';
import { Traj3DPanelOptions } from './types';

//TODO: duplicate with trackinfo in Calculation Utils, to be merged
export type TrackInfo = { Offset: number; TrackWidth: number; Position: 'Inner' | 'Outer' };
export interface Traj3DPlotFieldConfig extends GraphFieldConfig {
  fontSize: number;
}
export const defaultGeosteeringPlotFieldConfig: Traj3DPlotFieldConfig = {
  ...defaultGraphConfig,

  fontSize: 16,
};

function getGeosteeringPlotFieldConfig(): SetFieldConfigOptionsArgs<Traj3DPlotFieldConfig> {
  let fc = getGraphFieldConfig(defaultGeosteeringPlotFieldConfig as LogPlotFieldConfig);
  let oldUseCustomConf = fc.useCustomConfig!;
  fc.useCustomConfig = (builder) => {
    oldUseCustomConf(builder);

    builder.addNumberInput({
      path: 'fontSize',
      name: 'Font Size',
      defaultValue: defaultGeosteeringPlotFieldConfig.fontSize,
    });
  };
  return fc as any;
}

export const plugin = new PanelPlugin<Traj3DPanelOptions, Traj3DPlotFieldConfig>(Traj3DPanel).useFieldConfig(
  getGeosteeringPlotFieldConfig()
);

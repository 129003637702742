import React, { useEffect, useState } from 'react';
import { PanelProps } from '@grafana/data';
import { Traj3DPanelOptions } from './types';
import ReactECharts from 'echarts-for-react';
import { ECharts } from 'echarts';
// import { traj } from './GeosteeringOption';
// import { HorizontalGroup, Label, Slider, VerticalGroup } from '@grafana/ui';
import Traj3dOptions from './Traj3dOptions2';

interface Props extends PanelProps<Traj3DPanelOptions> {}

export const Traj3DPanel: React.FC<Props> = ({ options, data, width, height, onOptionsChange }) => {
  // export const GeosteeringPanel: React.FC<Props> = (ops) => {

  const [echartInst, setEchartInst] = useState<ECharts | undefined>(undefined);
  const [traj3dOption, setTraj3dOption] = useState<Traj3dOptions | undefined>(undefined);
  // const [aziViewAngle, setAziViewAngle] = useState(options.azimuthViewAngle);

  useEffect(() => {
    if (echartInst !== undefined && traj3dOption !== undefined) {
      traj3dOption.setData(data, true);
    }
    // setLoading(data.state === LoadingState.Loading ? true : false);
  }, [echartInst, data, traj3dOption]);
  return (
    <ReactECharts
      option={{}}
      style={{ width, height }}
      // showLoading={loading}
      onChartReady={(inst) => {
        setEchartInst(inst);
        setTraj3dOption(new Traj3dOptions(inst, data, options, onOptionsChange));
      }}
    />
  );
};
